import axios from 'axios';

export let Events = [];

export const Resources = [
  { id: 'a', building: 'Fotona', title: 'Patricia'},
  { id: 'n', building: 'Fotona', title: 'Carol'},
  { id: 'q', building: 'Fotona', title: 'Mariana'},
  { id: 'w', building: 'Fotona', title: 'Aline'},
  { id: 'b', building: 'Consulta', title: 'Patricia'},
  { id: 'h', building: 'Consulta', title: 'Carol'},
  { id: 'i', building: 'Consulta', title: 'Mariana'},
  { id: 'm', building: 'Consulta', title: 'Aline'},
  { id: 'l', building: 'Centro Cirurgico', title: 'Patricia'},
  { id: 'c', building: 'Centro Cirurgico', title: 'Carol'},
  { id: 'x', building: 'Centro Cirurgico', title: 'Mariana'},
  { id: 'y', building: 'Centro Cirurgico', title: 'Aline'},
  { id: 'das', building: 'Spectra', title: 'Patricia'},
  { id: 'asf', building: 'Spectra', title: 'Carol'},
  { id: 'ads', building: 'Spectra', title: 'Mariana'},
  { id: 'saf', building: 'Spectra', title: 'Aline'},
]
  
  export const Buildings = [
    { id: 'gfgsdf', name: 'Fotona' },
    { id: 'gafdhg', name: 'Consulta' },
    { id: 'rebjr', name: 'Centro Cirurgico' },
  ];
  
  export   const Professionals = [
    { id: 'at23t', name: 'Patricia' },
    { id: 'nreywre', name: 'Carol' },
    { id: 'dsaf', name: 'Aline' },
    { id: 'nasd', name: 'Mariana' },
  ];
  
  export   const Procedimentos = [
    { id: 'twretw', name: 'Laser Fotona' },
    { id: 'rewtwertwer', name: 'Consulta' },
    { id: 'retwer', name: 'Botox' },
  ];
  
  export   const Pacientes = [
    {id: 'sdgasdg', name: 'Joao'},
    {id: 'asdgasdg', name: 'Maria'},
    {id: 'sdagasdg', name: 'Jose'},
  ]
  
  export function addEvent(newEvent) {
    Events.push(newEvent);
  }
  
  export function updateEvent(updatedEvent) {
    const index = Events.findIndex(event => event.eventId === updatedEvent.eventId);
    if (index !== -1) {
      Events[index] = updatedEvent;
    }
  }
  
  export function deleteEvent(eventId) {
    const index = Events.findIndex(event => event.eventId === eventId);
    if (index !== -1) {
      Events.splice(index, 1);
    }
  }

const BASE_URL = 'https://upqsv1xash.execute-api.us-east-1.amazonaws.com/prod/';

export const fetchProcedimentos = async () => {
  try {
    const response = await fetch(`${BASE_URL}get-procedimentos`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching Procedimentos:', error);
    return [];
  }
};

export const fetchPatients = async (searchTerm = '') => {
  try {
    const response = await fetch(`${BASE_URL}get-pacientes`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(searchTerm ? { search: searchTerm } : {}),
    });
    
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching Patients:', error);
    return [];
  }
};

export const createPatient = async (patient) => {
  try {
    const response = await fetch(`${BASE_URL}create-paciente`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(patient),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error creating patient:', error);
    throw error;
  }
};

export const createItem = async (item) => {
  try {
    const response = await fetch(`${BASE_URL}create-or-delete-item`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(item),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error creating item:', error);
    throw error;
  }
};

export const deleteItem = async (eventId) => {
  try {
    const response = await fetch(`${BASE_URL}create-or-delete-item`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ item: eventId }),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const data = await response.json();
    console.log(data);
    return data;
  } catch (error) {
    console.error('Error deleting item:', error);
    throw error;
  }
};

export const fetchEvents = async (queryParams = {}) => {
  try {
    const url = new URL(`${BASE_URL}get-history`);
    Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));

    const response = await fetch(url);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching Events:', error);
    return [];
  }
};


export const fetchAnamnesis = async (patientId = null) => {
  try {
    const response = await fetch(`${BASE_URL}get-anamnese`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(patientId ? { patient_id: patientId } : {}),
    });

    const data = await response.json();
    
    return (data.anamneses || []).map(item => ({
      id: item.anamnese_id,
      date: item.date || 'N/A',
      patient: item.patient_name || 'N/A',
      patientId: item.patient_id || 'N/A',
      content: item.anamnesis_text
    }));
  } catch (error) {
    console.error('Error fetching Anamnesis:', error);
    return [];
  }
};

export const sendToReception = async (data) => {
  try {
    const response = await fetch(`${BASE_URL}send-to-reception`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.error('Error sending to reception:', error);
    throw error;
  }
};

export const createBudget = async (budgetData) => {
  try {
    const response = await fetch(`${BASE_URL}create-budget`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(budgetData),
    });

    if (!response.ok) {
      throw new Error('HTTP error ' + response.status);
    }

    const result = await response.json();
    console.log(result);
    return result;
  } catch (error) {
    console.error('Error creating budget:', error);
    throw error;
  }
};

export const transcribeAudio = async (audioFile, apiKey) => {
  try {
    const formData = new FormData();
    formData.append('file', audioFile);
    formData.append('model', 'whisper-large-v3');
    formData.append('language', 'pt');
    formData.append('prompt', 'Transcrição de áudio em português brasileiro para uma clínica de dermatologia');

    const response = await axios.post(
      'https://api.groq.com/openai/v1/audio/transcriptions',
      formData,
      {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'multipart/form-data'
        }
      }
    );

    return response.data.text;
  } catch (error) {
    console.error('Transcription error:', error.response || error);
    throw error;
  }
};

export const generateAnamnesis = async (transcriptionText, selectedPatient) => {
  try {
    const response = await fetch(`${BASE_URL}create-anamnese`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        text: transcriptionText,
        patient: selectedPatient || null
      })
    });

    return response.data.anamnesis;
  } catch (error) {
    console.error('Anamnesis generation error:', error.response?.data || error);
    throw error;
  }
};