import React, { useState, useCallback, useMemo } from 'react';
import { fetchPatients } from '../api';
import debounce from 'lodash/debounce';
import './PacientSearchSelect.css';

function PacientSearchSelect({ onPatientSelect }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [patients, setPatients] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(null);

  // Debounced search function
  const debouncedSearch = useMemo(
    () => debounce(async (term) => {
      if (!term || term.length < 3) {
        setPatients([]);
        return;
      }

      setIsLoading(true);
      try {
        const results = await fetchPatients(term);
        setPatients(results);
      } catch (error) {
        console.error('Patient search error:', error);
        setPatients([]);
      } finally {
        setIsLoading(false);
      }
    }, 300),
    []
  );

  // Memoized search handler
  const handleSearch = useCallback((e) => {
    const term = e.target.value;
    setSearchTerm(term);
    
    // If a patient was selected, clicking to type should clear the selection
    if (selectedPatient) {
      setSelectedPatient(null);
    }
    
    debouncedSearch(term);
  }, [debouncedSearch, selectedPatient]);

  // Memoized patient selection
  const handlePatientSelection = useCallback((patient) => {
    onPatientSelect(patient);
    setSelectedPatient(patient);
    setSearchTerm('');
    setPatients([]);
  }, [onPatientSelect]);

  return (
    <div className="patient-search-container">
      <div className="search-input-container">
        <input
          type="text"
          value={searchTerm}
          onChange={handleSearch}
          placeholder={selectedPatient ? selectedPatient.Nome : "Buscar paciente"}
          className="search-input"
        />
      </div>

      {isLoading && <div className="loading-indicator">Carregando...</div>}

      {patients.length > 0 && (
        <div className="patient-dropdown">
          {patients.map((patient) => (
            <div
              key={patient.pacientId} 
              onClick={() => handlePatientSelection(patient)}
              className="patient-option"
            >
              <span className="patient-name">{patient.Nome}</span>
              {patient.CPF && <span className="patient-cpf">CPF: {patient.CPF}</span>}
            </div>
          ))}
        </div>
      )}

      {searchTerm && !isLoading && patients.length === 0 && (
        <div className="no-results">
          Nenhum paciente encontrado
        </div>
      )}
    </div>
  );
}

export default React.memo(PacientSearchSelect);
