import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useTable, useFilters } from 'react-table';
import { Professionals, Pacientes, fetchPatients, fetchEvents, fetchAnamnesis } from '../api';
import PatientSearchSelect from '../PacientSearchSelect/PacientSearchSelect';
import Spinner from '../Spinner/Spinner';
import './Historico.css';

function Historico() {

  const [events, setEvents] = useState([]);
  const [professional, setProfessional] = useState('');
  const [patient, setPatient] = useState('');
  const [patients, setPatients] = useState([]);
  const [activeTab, setActiveTab] = useState('anamnesis');
  const [showAnamnesisModal, setShowAnamnesisModal] = useState(false);
  const [selectedAnamnesis, setSelectedAnamnesis] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [anamnesisData, setAnamnesisData] = useState([]);
  const [filteredAnamnesisData, setFilteredAnamnesisData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const professionalRef = useRef('');
  const patientRef = useRef('');

  useEffect(() => {
    professionalRef.current = professional;
  }, [professional]);

  useEffect(() => {
    patientRef.current = patient;
  }, [patient]);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === 'Escape') {
        handleCloseModal();
      }
    };
    
    window.addEventListener('keydown', handleEsc);
    
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  useEffect(() => {
    const loadInitialAnamnesis = async () => {
      setIsInitialLoading(true);
      try {
        const initialData = await fetchAnamnesis();
        setAnamnesisData(initialData);
        setFilteredAnamnesisData(initialData);
      } catch (error) {
        console.error('Error fetching initial anamnesis:', error);
      } finally {
        setIsInitialLoading(false);
      }
    };
    loadInitialAnamnesis();
  }, []);

  const handleSearch = () => {
    const fetchEventsData = async () => { 
      // console.log(professionalRef.current.id, patientRef.current.pacientId);
      // Only send "professional" if its not null or ""
      // Only send "patient" if its not null or ""
      const queryParams = {};
      if (patientRef.current.Nome) {
        queryParams.patient = patientRef.current.Nome;
      }
      if (professionalRef.current.id) {
        queryParams.professional = professionalRef.current.id;
      }
      console.log(queryParams)
      const events = await fetchEvents(queryParams);
      console.log(events);
      setEvents(events);
    };
  
    fetchEventsData();
  };
  
  const handlePatientSearch = async (searchTerm) => {
    const patients = await fetchPatients(searchTerm);
    setPatients(patients);
    console.log(patients);
  };

  const handlePatientSelection = (selectedPatient) => {
    setPatient(selectedPatient);
    // handleSearch();
  };
  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Título',
        accessor: data => data.title ? data.title.S : " ", // Adjusted accessor
        Filter: TextFilter,
      },
      {
        Header: 'Dia',
        accessor: data => data.start ? data.start.S.split('T')[0] : " ", // Adjusted accessor to get date before 'T'
        Filter: TextFilter,
      },
      {
        Header: 'Horário',
        accessor: data => data.start ? data.start.S.split('T')[1] : " ", // Adjusted accessor to get time after 'T'
        Filter: TextFilter,
      },
      {
        Header: 'Profissional',
        accessor: data => data.professional ? data.professional.S : " ", // Adjusted accessor
        Filter: TextFilter,
      },
      // {
      //   Header: 'Place',
      //   accessor: data => data.place ? data.place.S : " ", // Adjusted accessor
      //   Filter: TextFilter,
      // },
      {
        Header: 'Paciente',
        accessor: data => data.patient ? data.patient.S : " ", // Adjusted accessor
        Filter: TextFilter,
      },
      {
        Header: 'Procedimento',
        accessor: data => data.procedure ? data.procedure.S : " ", // Adjusted accessor
        Filter: TextFilter,
      },
      // Add more columns as needed```
    ],
    []
  );
  
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data:events }, useFilters);
  

  const tabs = [
    { id: 'anamnesis', label: 'Anamnese' },
    { id: 'patient', label: 'Pacientes' },
    { id: 'professional', label: 'Profissionais' },
  ];

  const handlePatientSelect = async (patient) => {
    setSelectedPatient(patient);
    
    setIsLoading(true);
    try {
      const filtered = await fetchAnamnesis(patient.pacientId);
      setFilteredAnamnesisData(filtered);
    } catch (error) {
      console.error('Error fetching anamnesis:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewAnamnesis = (id) => {
    const anamnesis = filteredAnamnesisData.find(item => item.id === id);
    setSelectedAnamnesis(anamnesis.content);
    setShowAnamnesisModal(true);
  };

  const handleCloseModal = () => {
    setShowAnamnesisModal(false);
    setSelectedAnamnesis(null);
  };

  // Reset filter when changing tabs
  useEffect(() => {
    if (activeTab !== 'anamnesis') {
      setSelectedPatient(null);
      setFilteredAnamnesisData(anamnesisData);
    }
  }, [activeTab]);

  return (
    <div className="historico-container">
      <div className="search-controls">
        <div className="tabs-container">
          <div className="tabs-list">
            {tabs.map(tab => (
              <button
                key={tab.id}
                className={`tab-item ${activeTab === tab.id ? 'active' : ''}`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>

        {activeTab === 'patient' && (
          <div>
            <PatientSearchSelect 
              onPatientSelect={handlePatientSelect} 
            />
            {selectedPatient && (
              <div className="selected-patient-info">
                <p>Paciente selecionado: {selectedPatient.Nome}</p>
              </div>
            )}
          </div>
        )}

        {activeTab === 'professional' && (
          <div className="no-data">
            <h3>Busca por profissional ainda não disponível.</h3>
          </div>
        )}

        {activeTab === 'anamnesis' && (
          <div className="anamnesis-table-container">
            <PatientSearchSelect 
              onPatientSelect={handlePatientSelect} 
            />
            
            {isInitialLoading || isLoading ? (
              <Spinner size="medium" />
            ) : (
              <table className="history-table">
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Paciente</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredAnamnesisData.map((item) => (
                    <tr key={item.id}>
                      <td>{item.date}</td>
                      <td>{item.patient}</td>
                      <td>
                        <button 
                          className="view-anamnesis-btn"
                          onClick={() => handleViewAnamnesis(item.id)}
                        >
                          Ver anamnese
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}

        {activeTab !== 'anamnesis' && (
          <button className="search-button" onClick={handleSearch}>
            Procurar procedimentos
          </button>
        )}
      </div>

      {rows.length > 0 ? (
        <table {...getTableProps()} className="history-table">
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <div className="no-data">
          <p>Nenhum dado disponível</p>
        </div>
      )}

      {/* Add the modal */}
      {showAnamnesisModal && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <div className="modal-header">
              <h2>Anamnese</h2>
              <button className="close-button" onClick={handleCloseModal}>×</button>
            </div>
            <div className="modal-body">
              <pre className="anamnesis-text">{selectedAnamnesis}</pre>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

// Define a default UI for filtering
function TextFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  return (
    <input
      className="filter-input"
      value={filterValue || ''}
      onChange={e => setFilter(e.target.value || undefined)}
      placeholder="Filtrar..."
    />
  );
}

export default Historico;


