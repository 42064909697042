import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Sidebar.css';
import { FaHome, FaCalendarAlt, FaHistory, FaMicrophone, FaUserFriends, FaBars, FaTimes } from 'react-icons/fa';

function Sidebar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const closeSidebar = () => {
        setIsOpen(false);
    };

    return (
        <>
            <button className="sidebar-toggle" onClick={toggleSidebar}>
                {isOpen ? <FaTimes /> : <FaBars />}
            </button>
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <Link to="/transcription" className="sidebar-item" onClick={closeSidebar}>
                    <FaMicrophone className="sidebar-icon" />
                    <span>Transcrição</span>
                </Link>
                <Link to="/historico" className="sidebar-item" onClick={closeSidebar}>
                    <FaHistory className="sidebar-icon" />
                    <span>Histórico</span>
                </Link>
                <Link to="/protocolo" className="sidebar-item" onClick={closeSidebar}>
                    <FaHome className="sidebar-icon" />
                    <span>Protocolos</span>
                </Link>
                <Link to="/agenda" className="sidebar-item" onClick={closeSidebar}>
                    <FaCalendarAlt className="sidebar-icon" />
                    <span>Agenda</span>
                </Link>
                <Link to="/pacientes" className="sidebar-item" onClick={closeSidebar}>
                    <FaUserFriends className="sidebar-icon" />
                    <span>Pacientes</span>
                </Link>
            </div>
        </>
    );
}

export default Sidebar;
