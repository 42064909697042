import React from 'react';
import './Spinner.css';

const Spinner = ({ 
  size = 'medium', 
  color = '#3498db' 
}) => {
  return (
    <div className="spinner-container">
      <div 
        className={`spinner spinner-${size}`} 
        style={{ borderTopColor: color }}
      ></div>
    </div>
  );
};

export default Spinner; 